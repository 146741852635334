import React, { createContext, useContext, useState, useCallback } from 'react';

interface NavigationTrackerContextValue {
  setLastVisitedPath: (basePath: string, path: string) => void;
  getLastVisitedPath: (basePath: string) => string | undefined;
}

const NavigationTrackerContext = createContext<NavigationTrackerContextValue | undefined>(undefined);

export const NavigationTrackerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [lastVisitedPaths, setLastVisitedPaths] = useState<{ [key: string]: string }>({});

  const setLastVisitedPath = useCallback((basePath: string, path: string) => {
    setLastVisitedPaths(prevState => ({ ...prevState, [basePath]: path }));
  }, []);

  const getLastVisitedPath = useCallback((basePath: string): string | undefined => {
    return lastVisitedPaths[basePath];
  }, [lastVisitedPaths]);

  const contextValue = React.useMemo(() => ({
    setLastVisitedPath,
    getLastVisitedPath,
  }), [setLastVisitedPath, getLastVisitedPath]);

  return (
    <NavigationTrackerContext.Provider value={contextValue}>
      {children}
    </NavigationTrackerContext.Provider>
  );
};

export const useNavigationTracker = () => {
  const context = useContext(NavigationTrackerContext);
  if (!context) {
    throw new Error('useNavigationTracker must be used within a NavigationTrackerProvider');
  }
  return context;
};