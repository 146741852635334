import { useState } from 'react'
import { toast } from './use-toast'
import downloadCSV from '../utils/downloadCSV'

interface DownloadOptions<T> {
    getData: () => T[]
    filename: string
    sanitizeData?: (data: T[]) => Partial<T>[]
    onSuccess?: () => void
    onError?: (error: Error) => void
}

export const useCSVDownload = <T extends Record<string, any>>() => {
    const [isDownloading, setIsDownloading] = useState(false)

    const handleDownload = async ({
        getData,
        filename,
        sanitizeData,
        onSuccess,
        onError
    }: DownloadOptions<T>) => {
        const data = getData()
        
        if (!data.length) return

        setIsDownloading(true)
        try {
            const processedData = sanitizeData ? sanitizeData(data) : data
            await downloadCSV(processedData, filename)
            
            toast({
                title: "Success",
                description: "CSV file has been generated successfully",
                variant: "default",
            })
            
            onSuccess?.()
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to generate CSV file",
                variant: "destructive",
            })
            
            onError?.(error as Error)
        } finally {
            setIsDownloading(false)
        }
    }

    return {
        isDownloading,
        handleDownload
    }
}