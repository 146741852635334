import { useNavigate, useParams } from 'react-router-dom'
import { Button } from "./ui/button"
import { Table, TableBody, TableCell, TableRow } from "./ui/table"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { ArrowLeft } from 'lucide-react'
import { useTrackNavigation } from '../hooks/useTrackNavigation'
import { useTripContext } from '../contexts/TripContext'
import { Trip } from '../types/trip'
import { convertDateFormat } from '../utils/formatDate'

const TripDetailComponent = (): JSX.Element => {
  const { tripId } = useParams<{ tripId: string }>()
  const { data } = useTripContext()
  const trip: Trip | undefined = data.find((trip: Trip) => trip.trip_id === tripId)
  const navigate = useNavigate()
  useTrackNavigation()

  const handleBack = (): void => {
    navigate('/trips')
  }

  if (!trip) {
    return <div className="p-4">Trip not found</div>
  }

  return (
    <div className="p-4">
      <Card className="bg-white">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-2xl font-bold">Trip Details</CardTitle>
          <Button onClick={handleBack} variant="outline" size="sm">
            <ArrowLeft className="mr-2 h-4 w-4" /> Back to List
          </Button>
        </CardHeader>
        <CardContent className="pt-5">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="font-medium">User UUID</TableCell>
                <TableCell data-testid={'user_uuid'}>{trip.user_uuid}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Trip ID</TableCell>
                <TableCell data-testid={'trip-id'}>{trip.trip_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Vehicle ID</TableCell>
                <TableCell data-testid={'vehicle_id'}>{trip.vehicle_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">License Plate</TableCell>
                <TableCell data-testid={'license_plate'}>{trip.license_plate ? trip.license_plate.split('_').pop() || trip.license_plate : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Start Time</TableCell>
                <TableCell data-testid={'trip_start_time'}>{convertDateFormat(trip.trip_start_time)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">End Time</TableCell>
                <TableCell data-testid={'end_time'}>{convertDateFormat(trip.end_time)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Occupancy</TableCell>
                <TableCell data-testid={'occupancy'}>{trip.occupancy}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">In Corridor</TableCell>
                <TableCell data-testid={'in_corridor'}>{trip.in_corridor === 1 ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Valid</TableCell>
                <TableCell data-testid={'valid'}>{trip.valid === 1 ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Transponder ID</TableCell>
                <TableCell data-testid='transponder_id'>{trip.transponder_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Ambient Light Reverify</TableCell>
                <TableCell data-testid='ambient_light_reverify'>{trip.ambient_light_reverify}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Ambient Light Verify</TableCell>
                <TableCell data-testid='ambient_light_verify'>{trip.ambient_light_verify}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Attempts Reverify</TableCell>
                <TableCell data-testid='attempts_reverify'>{trip.attempts_reverify}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Passenger Camera Dwell Time</TableCell>
                <TableCell data-testid='passenger_camera_dwell_time'>{trip.passenger_camera_dwell_time}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Reverify Camera Dwell Time 1</TableCell>
                <TableCell data-testid='reverify_camera_dwell_time_1'>{trip.reverify_camera_dwell_time_1}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Reverify Camera Dwell Time 2</TableCell>
                <TableCell data-testid='reverify_camera_dwell_time_2'>{trip.reverify_camera_dwell_time_2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Auto Close</TableCell>
                <TableCell data-testid='auto_close'>{trip.auto_close === 'true' ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Started Offline</TableCell>
                <TableCell data-testid='started_offline'>{trip.started_offline === "true" ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Ended Offline</TableCell>
                <TableCell data-testid='ended_offline'>{trip.ended_offline === "true" ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">No GPS</TableCell>

                <TableCell data-testid='no_gps'>
                  {trip.no_gps === null || trip.no_gps === undefined || trip.no_gps === '' ? 'Null' : trip.no_gps === 'true' ? 'Yes' : trip.no_gps === 'false' ? 'No' : 'No'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Reported Time</TableCell>
                <TableCell data-testid='reported_time'>
                  {trip.reported_time &&
                    trip.reported_time !== 'NULL' &&
                    trip.reported_time !== null &&
                    !isNaN(new Date(trip.reported_time.replace(' ', 'T')).getTime())
                    ? convertDateFormat(trip.reported_time.replace(' ', 'T'))
                    : 'N/A'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}

export default TripDetailComponent