import React from 'react';
import logger from '../services/logger';

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
    constructor(props: any) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: any) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error: any, errorInfo: any) {
      logger.log(`Uncaught error: ${error}`, errorInfo)
        .catch(logError => {
          console.error('Failed to log error:', logError);
        });
    }
  
    render() {
      if (this.state.hasError) {
        return <h1>Something went wrong. Please refresh the page.</h1>;
      }
  
      return this.props.children; 
    }
}
