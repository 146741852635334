import { Skeleton } from "./ui/skeleton"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table"

interface TableSkeletonProps {
  rows?: number;
  columns?: number;
}

export function DataTableSkeleton({ rows = 5, columns = 5 }: TableSkeletonProps) {
  return (
    <div className="space-y-4 bg-white dark:bg-gray-800 rounded-xl p-4 shadow-md border border-slate-200 dark:border-slate-700">
      <div className="flex items-center justify-between">
        <Skeleton className="h-10 w-[350px] max-w-lg animate-pulse" />
        <Skeleton className="h-10 w-[100px] animate-pulse" />
      </div>
      <div className="rounded-md border border-slate-200 dark:border-slate-700">
        <Table data-testid="skeleton-table">
          <TableHeader>
            <TableRow>
              {Array.from({ length: columns }).map((_, i) => (
                <TableHead key={i}>
                  <Skeleton className="h-4 w-24 animate-pulse" />
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {Array.from({ length: rows }).map((_, row) => (
              <TableRow key={row}>
                {Array.from({ length: columns }).map((_, cell) => (
                  <TableCell key={cell}>
                    <Skeleton className="h-4 w-full animate-pulse" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-between">
        <Skeleton className="h-10 w-[180px] animate-pulse" />
        <div className="flex items-center space-x-2">
          <Skeleton className="h-10 w-[100px] animate-pulse" />
          <Skeleton className="h-10 w-[100px] animate-pulse" />
        </div>
      </div>
    </div>
  )
}

