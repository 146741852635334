"use client"
import { useEffect, useRef, useState } from "react"
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"
import { ArrowUpDown, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react'

import { Button } from "../../components/ui/button"
import { Input } from "../../components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select"

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  onRowClick?: (item: TData) => void
  paginationState?: {
    currentPage: number
    itemsPerPage: number
    onPageChange: (page: number) => void
    onItemsPerPageChange: (itemsPerPage: number) => void
  }
  onDataChange?: (data: TData[]) => void
  tableFilterState?: {
    tableFilter: string
    onTableFilterChange?: (filter: string) => void
  }
}

const DataTable = <TData, TValue>({
  columns,
  data,
  onRowClick,
  paginationState,
  onDataChange,
  tableFilterState,
}: DataTableProps<TData, TValue>) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const previousDataRef = useRef<TData[]>(data);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnFilters,
      globalFilter: tableFilterState?.tableFilter ?? "",
      pagination: {
        pageIndex: (paginationState?.currentPage ?? 1) - 1,
        pageSize: paginationState?.itemsPerPage ?? 10,
      },
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: (updater) => {
      const filter = typeof updater === 'function' ? updater(tableFilterState?.tableFilter ?? "") : updater;
      tableFilterState?.onTableFilterChange && tableFilterState.onTableFilterChange(filter);
    },
    onPaginationChange: (updater) => {
      const pagination = typeof updater === 'function' ? updater(table.getState().pagination) : updater;
      paginationState?.onPageChange(pagination.pageIndex + 1);
      paginationState?.onItemsPerPageChange(pagination.pageSize);
    },
    enableFilters: true,
    enableGlobalFilter: true,
  });

  useEffect(() => {
    if (onDataChange) {
      const filteredData = table.getFilteredRowModel().rows.map(row => row.original);
      if (JSON.stringify(previousDataRef.current) !== JSON.stringify(filteredData)) {
        previousDataRef.current = filteredData;
        onDataChange(filteredData);
      }
    }
  }, [table.getFilteredRowModel().rows, onDataChange]);

  return (
    <div className="space-y-4 bg-white rounded-xl p-4 shadow-md border border-slate-200">
      <div className="flex items-center justify-between">
        <Input
          placeholder="Search all columns..."
          value={tableFilterState?.tableFilter ?? ""}
          onChange={(event) => tableFilterState?.onTableFilterChange && tableFilterState.onTableFilterChange(String(event.target.value))}
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns <ChevronDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="bg-white"
            align="end"
          >
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.columnDef.header?.toString() ?? column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <ArrowUpDown className="ml-2 h-4 w-4" />,
                            desc: <ArrowUpDown className="ml-2 h-4 w-4" />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => onRowClick && onRowClick(row.original)}
                  className={`${onRowClick ? "cursor-pointer" : ""} hover:bg-slate-100`}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      data-testid={cell.column.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-between">
        <Select
          value={`${table.getState().pagination.pageSize}`}
          onValueChange={(value) => {
            table.setPageSize(Number(value))
          }}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select rows per page" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            {[10, 50, 100, 200, 500].map((pageSize) => (
              <SelectItem 
                key={pageSize} 
                value={`${pageSize}`}
                className="hover:bg-slate-100 cursor-pointer"
              >
                {pageSize} rows
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => paginationState?.onPageChange((paginationState.currentPage ?? 1) - 1)}
            disabled={paginationState?.currentPage === 1}
          >
            <ChevronLeft className="h-4 w-4" />
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => paginationState?.onPageChange((paginationState.currentPage ?? 1) + 1)}
            disabled={table.getPageCount() <= (paginationState?.currentPage ?? 1)}
          >
            Next
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DataTable