import { createDataContext } from './PostContext';

interface ResetRequestBody {
    user_id: string
    date: string
}

interface ResetResponse {
    message: string
}

const parseResetResponse = (response: any): ResetResponse[] => {
    const parsedResponse: ResetResponse = {
        message: response
    };
    return [parsedResponse];
};

export const { useDataContext: useResetContext, DataProvider: ResetProvider } = createDataContext<ResetResponse, ResetRequestBody>(
  'resetLookBackStartDateGAAPI',
  '/resetLookBackStartDateGA',
  parseResetResponse
);