/*
  This file is used to create a generic context for fetching data from the API.
  It is used to fetch data from the API and store it in the state.
*/

import React, { createContext, useState, useContext, useEffect } from 'react';
import { get } from 'aws-amplify/api';
import { getValidAuthToken } from '../utils/auth';
import logger from '../services/logger';

interface DataContextType<T, Q> {
  data: T[];
  loading: boolean;
  error: string | null;
  fetchData: (queryParams: Q) => Promise<void>;
  clearData: () => void;
}

// Generic Context Creator
export const createDataContext = <T, Q>(
  apiName: string,
  path: string,
  parseResponse: (response: any) => T[],
) => {

  const DataContext = createContext<DataContextType<T, Q>>({
    data: [],
    loading: false,
    error: null,
    fetchData: async () => {
      console.log('Default fetchData called - context not properly initialized');
    },
    clearData: () => { }
  });

  const useDataContext = () => useContext(DataContext);

  const DataProvider = ({ children, initialData }: { children: React.ReactNode, initialData?: T[] }) => {
    const [data, setData] = useState<T[]>(() => {
      const storedData = localStorage.getItem(`${apiName}-${path}-data`);
      return storedData ? JSON.parse(storedData) : initialData || [];
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      localStorage.setItem(`${apiName}-${path}-data`, JSON.stringify(data));
    }, [data]);

    const fetchData = async (queryParams: Q) => {
      setLoading(true);
      setError(null);
      try {
        const authToken = await getValidAuthToken();
        const restOperation = get({
          apiName,
          path,
          options: {
            headers: { Authorization: authToken || '' },
            queryParams: queryParams as Record<string, string>
          }
        });

        const response = await restOperation.response;
        if (response.body instanceof ReadableStream) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let result = '';
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            result += decoder.decode(value);
          }
          const parsedData = parseResponse(JSON.parse(result));
          setData(parsedData);
        } else {
          setData(parseResponse(response));
        }
      } catch (err) {
        await logger.log(`Error fetching data: ${err}`, { source: { file: __filename } });
        setError(err instanceof Error ? err.message : 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    const clearData = () => {
      setData([]);
    };

    return (
      <DataContext.Provider value={{ data, loading, error, fetchData, clearData }}>
        {children}
      </DataContext.Provider>
    );
  };

  return { useDataContext, DataProvider };
};

