import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, CardHeader, CardTitle } from './ui/card'
import { Button } from './ui/button'
import { Input } from './ui/input'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from './ui/select'
import { useTripContext } from '../contexts/TripContext'
import DataTable from './common/DataTable'
import { generateColumns, filterColumns } from '../utils/tableUtils'
import { Trip, TripQueryState } from '../types/trip'
import { Spinner } from './ui/spinner'
import { usePersistedState } from '../hooks/usePersistedState'
import { formatDate } from '../utils/formatDate'
import { AlertView } from './common/Alert'
import { ColumnDef } from '@tanstack/react-table'
import { Filter } from '../types/filter'
import { useCSVDownload } from '../hooks/useCSVDownload'
import { Download } from 'lucide-react'
import { RefreshCw } from 'lucide-react'
import { DataTableSkeleton } from './TableSkeleton'
import { useTrackNavigation } from '../hooks/useTrackNavigation'
import { useToast } from '../hooks/use-toast'

function TripQueryComponent(): JSX.Element {
    const navigate = useNavigate()
    const { data: trips, loading, error, fetchData: fetchTrips, clearData: clearTrips } = useTripContext()
    const [downloadableTrips, setDownloadableTrips] = useState<Trip[]>(trips)
    const [columns, setColumns] = useState<ColumnDef<Trip, any>[]>([])
    const [filteredTrips, setFilteredTrips] = useState<Trip[]>([])
    const { isDownloading, handleDownload } = useCSVDownload<Trip>()
    const { toast } = useToast()
    useTrackNavigation()

    useEffect(() => {
        if (filteredTrips.length > 0) {
            setDownloadableTrips(filteredTrips)
        }
    }, [filteredTrips])

    const getDefaultState = (): TripQueryState => {
        const defaultEndDate = new Date()
        const defaultStartDate = new Date(defaultEndDate)
        defaultStartDate.setDate(defaultStartDate.getDate() - 2)

        return {
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 10,
            phoneNumber: '',
            autoCloseFilter: 'all',
            startDate: formatDate(defaultStartDate),
            endDate: formatDate(defaultEndDate),
            licensePlate: '',
            grantedFilter: 'all',
            validFilter: 'all',
            tableFilter: '',
        }
    }

    const [queryState, setQueryState] = usePersistedState<TripQueryState>({
        storageKey: 'tripQueryState',
        defaultValue: getDefaultState()
    })

    useEffect(() => {
        const value = filterTrips(trips)
        if (value) {
            setColumns(value[0])
            setFilteredTrips(value[1])
        } else {
            setFilteredTrips([])
        }
    }, [
        trips,
        queryState.phoneNumber,
        queryState.licensePlate,
        queryState.autoCloseFilter,
        queryState.grantedFilter,
        queryState.validFilter,
    ])

    const setCurrentPage = useCallback((currentPage: number) => {
        setQueryState(prev => ({ ...prev, currentPage }))
    }, [setQueryState])

    const setItemsPerPage = useCallback((newItemsPerPage: number) => {
        setQueryState(prev => ({
            ...prev,
            itemsPerPage: newItemsPerPage,
            currentPage: 1
        }))
    }, [setQueryState])

    const resetToDefaults = () => {
        setQueryState(getDefaultState())
        clearTrips()
    }

    const setTableFilter = useCallback((filter: string) => {
        setQueryState(prev => ({ ...prev, tableFilter: filter }))
    }, [setQueryState])

    const handleFindClick = (): void => {
        fetchTrips({ startDate: queryState.startDate, endDate: queryState.endDate })
        if (error) {
            toast({
                title: 'Error',
                description: 'Failed to fetch trips. Please try again later.',
                variant: 'destructive',
            })
        } else {
            clearTrips()
            setQueryState({
                ...queryState,
                searchTerm: '',
                currentPage: 1,
                phoneNumber: '',
                autoCloseFilter: 'all',
                licensePlate: '',
                grantedFilter: 'all',
                validFilter: 'all',
                tableFilter: '',
            })
        }
    }

    const handleDownloadCSV = () => {
        if (downloadableTrips.length === 0) return
        handleDownload({
            getData: () => downloadableTrips,
            filename: 'trip_data.csv',
            sanitizeData: (trips) => trips.map(({ user_id, ...rest }) => rest)
        })
    }

    const handleDataChange = useCallback((data: Trip[]) => {
        setDownloadableTrips(data)
    }, [setDownloadableTrips])

    const filterTrips = useCallback((trips: Trip[]): [ColumnDef<Trip, any>[], Trip[]] | undefined => {
        if (trips.length > 0) {
            // Build additional filters
            const additionalFilters: Filter[] = [
                { field: 'user_id', value: buildFilter('user_id', queryState.phoneNumber) },
                { field: 'license_plate', value: buildFilter('license_plate', queryState.licensePlate) },
                { field: 'auto_close', value: buildFilter('auto_close', queryState.autoCloseFilter) },
                { field: 'in_corridor', value: buildFilter('in_corridor', queryState.grantedFilter) },
                { field: 'valid', value: buildFilter('valid', queryState.validFilter, 'all') ? Number(queryState.validFilter) : undefined },
            ];
     
            const filtered = trips.filter(trip => {
                return additionalFilters.every(filter => {
                    if (filter.value === undefined || filter.value === '') return true; // Skip empty filters
     
                    const value = trip[filter.field];
     
                    // Handle boolean fields directly
                    if (typeof value === 'boolean') {
                        return value === (filter.value === 'true');
                    }
     
                    // Precompute lowercased values for efficiency
                    const tripValue = String(value).trim().toLowerCase();
                    const filterValue = String(filter.value).trim().toLowerCase();
     
                    // Handle partial matches for specific fields
                    if (filter.field === 'license_plate') {
                        const formattedValue = 'ga_' + filterValue;
                        return tripValue.includes(formattedValue);
                    }
     
                    // General case for partial matching (like user_id)
                    if (['user_id'].includes(filter.field)) {
                        return tripValue.includes(filterValue);
                    }
     
                    // Default strict equality check (case-insensitive)
                    return tripValue === filterValue;
                });
            });

            const defaultColumns: ColumnDef<Trip, any>[] = [
                { accessorKey: 'trip_id', header: 'Trip ID' },
                { accessorKey: 'trip_start_time', header: 'Start Time' },
                { accessorKey: 'end_time', header: 'End Time' },
                { accessorKey: 'occupancy', header: 'Occupancy' },
                { accessorKey: 'in_corridor', header: 'In Corridor' },
                { accessorKey: 'valid', header: 'Valid' },
                { accessorKey: 'transponder_id', header: 'Transponder ID' },
                { accessorKey: 'no_gps', header: 'No GPS' },
                { accessorKey: 'license_plate', header: 'License Plate' },
            ];
    
            const allColumns = generateColumns<Trip>(trips);
            const excludeFields = [
                '_internal_id',
                'created_at',
                'updated_at',
                'user_uuid',
                'vehicle_id',
                'ambient_light_reverify',
                'ambient_light_verify',
                'app_load_time',
                'attempts_reverify',
                'passenger_camera_dwell_time',
                'reverify_camera_dwell_time_1',
                'reverify_camera_dwell_time_2',
                'auto_close',
                'started_offline',
                'ended_offline',
                'user_id',
                'reported_time',
            ];
    
            const filteredColumns = filterColumns(allColumns, excludeFields);
            if(trips.length === 0) {
                return [defaultColumns, filtered]
            } else {
                return [filteredColumns, filtered]
            }
        }
        return undefined;
    }, [
        trips,
        queryState.phoneNumber,
        queryState.licensePlate,
        queryState.autoCloseFilter,
        queryState.grantedFilter,
        queryState.validFilter,
    ])
    
    // Helper function to build filters
    const buildFilter = useCallback((field: keyof Trip, value: any, allValue: any = 'all') => {
        return value === allValue ? undefined : value;
    }, []);

    const paginationState = {
        currentPage: queryState.currentPage,
        itemsPerPage: queryState.itemsPerPage,
        onPageChange: setCurrentPage,
        onItemsPerPageChange: setItemsPerPage,
    }

    const tableFilterState = {
        tableFilter: queryState.tableFilter,
        onTableFilterChange: setTableFilter,
    }

    return (
        <div className="top-margin p-4">
            <div className="p-0">
                <div className="flex flex-col md:flex-row gap-5 mb-2">
                <Card className="flex-1 md:max-w-[55rem] bg-white">
                        <CardHeader className="pb-2">
                            <CardTitle className="flex justify-between items-center">
                                <span>Filters</span>
                                <div className="text-right">
                                    <p className="text-sm font-medium text-muted-foreground">Trip Count</p>
                                    <div className="bg-primary/10 px-2 py-1 rounded-md">
                                        <p className="text-lg font-semibold text-primary" data-testid="trip-count">{downloadableTrips.length}</p>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="pt-0 pb-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                                <div className="space-y-1">
                                    <label htmlFor="phoneNumber" className="text-sm font-medium">Phone Number</label>
                                    <Input
                                        id="phoneNumber"
                                        className="w-full"
                                        type="tel"
                                        placeholder="Enter phone number"
                                        value={queryState.phoneNumber}
                                        onChange={(e) => setQueryState({ ...queryState, phoneNumber: e.target.value })}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="licensePlate" className="text-sm font-medium">License Plate</label>
                                    <Input
                                        id="licensePlate"
                                        className="w-full"
                                        placeholder="Enter license plate"
                                        value={queryState.licensePlate}
                                        onChange={(e) => setQueryState({ ...queryState, licensePlate: e.target.value })}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="autoClose" className="text-sm font-medium">Auto-Close</label>
                                    <Select 
                                        value={queryState.autoCloseFilter}
                                        onValueChange={(value) => setQueryState({ ...queryState, autoCloseFilter: value })}
                                    >
                                        <SelectTrigger 
                                            id="autoClose" 
                                            className="w-full"
                                        >
                                            <SelectValue placeholder="All" />
                                        </SelectTrigger>
                                        <SelectContent className="bg-white">
                                            <SelectItem value="all" className="hover:bg-slate-100 cursor-pointer">All</SelectItem>
                                            <SelectItem value="true" className="hover:bg-slate-100 cursor-pointer">True</SelectItem>
                                            <SelectItem value="false" className="hover:bg-slate-100 cursor-pointer">False</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="inCorridor" className="text-sm font-medium">In Corridor</label>
                                    <Select 
                                        value={queryState.grantedFilter}
                                        onValueChange={(value) => setQueryState({ ...queryState, grantedFilter: value })}
                                    >
                                        <SelectTrigger 
                                            id="inCorridor" 
                                            className="w-full"
                                        >
                                            <SelectValue placeholder="All" />
                                        </SelectTrigger>
                                        <SelectContent className="bg-white">
                                            <SelectItem value="all" className="hover:bg-slate-100 cursor-pointer">All</SelectItem>
                                            <SelectItem value="1" className="hover:bg-slate-100 cursor-pointer">Yes</SelectItem>
                                            <SelectItem value="0" className="hover:bg-slate-100 cursor-pointer">No</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="valid" className="text-sm font-medium">Valid</label>
                                    <Select 
                                        value={queryState.validFilter}
                                        onValueChange={(value) => setQueryState({ ...queryState, validFilter: value })}
                                    >
                                        <SelectTrigger 
                                            id="valid" 
                                            className="w-full"
                                        >
                                            <SelectValue placeholder="All" />
                                        </SelectTrigger>
                                        <SelectContent className="bg-white">
                                            <SelectItem value="all" className="hover:bg-slate-100 cursor-pointer">All</SelectItem>
                                            <SelectItem value="1" className="hover:bg-slate-100 cursor-pointer">Yes</SelectItem>
                                            <SelectItem value="0" className="hover:bg-slate-100 cursor-pointer">No</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className="flex-1 md:max-w-md bg-white">
                        <CardHeader className="pb-2">
                            <CardTitle>Date Range</CardTitle>
                        </CardHeader>
                        <CardContent className="pt-0">
                            <div className="space-y-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="space-y-1">
                                        <label htmlFor="startDate" className="text-sm font-medium">
                                            Start Date
                                        </label>
                                        <Input
                                            value={queryState.startDate}
                                            id="startDate"
                                            type="date"
                                            className="w-full"
                                            onChange={(e) => setQueryState({ ...queryState, startDate: e.target.value })}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label htmlFor="endDate" className="text-sm font-medium">
                                            End Date
                                        </label>
                                        <Input
                                            value={queryState.endDate}
                                            id="endDate"
                                            type="date"
                                            className="w-full"
                                            onChange={(e) => setQueryState({ ...queryState, endDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <Button 
                                    onClick={handleFindClick}
                                    className="w-full bg-green-600 hover:bg-green-700 text-white"
                                >
                                    Find
                                </Button>
                            </div>
                        </CardContent>
                    </Card>

                    <div className="flex flex-col gap-2 md:justify-end self-end">
                        <Button
                            onClick={resetToDefaults}
                            variant="outline"
                            className="w-full md:w-auto bg-secondary hover:bg-secondary/90 text-secondary-foreground bg-green-600 text-white hover:bg-green-700"
                        >
                            <RefreshCw className="w-4 h-4 mr-2" />
                            Reset
                        </Button>
                        <Button
                            onClick={handleDownloadCSV}
                            variant="outline"
                            className="w-full md:w-auto bg-primary hover:bg-primary/90 text-primary-foreground bg-green-600 text-white hover:bg-green-700"
                            disabled={isDownloading || downloadableTrips.length === 0}
                            data-testid="download-csv-button"
                        >
                            {isDownloading ? (
                                <>
                                    <Spinner size="small" className="mr-2" />
                                    Downloading...
                                </>
                            ) : (
                                <>
                                    <Download className="w-4 h-4 mr-2" />
                                    Download CSV
                                </>
                            )}
                        </Button>
                    </div>
                </div>
            </div>

            <div className="mt-4 p-0">
                {error ? (
                    <AlertView
                        message="An error has occurred"
                        onAction={handleFindClick}
                        buttonTitle="Retry"
                    />
                ) : loading ? (
                    <DataTableSkeleton rows={8} columns={8} />
                ) : (
                    <DataTable
                        data={filteredTrips}
                        columns={columns}
                        onRowClick={(trip: Trip) => navigate(`/trips/${trip.trip_id}`)}
                        paginationState={paginationState}
                        onDataChange={handleDataChange}
                        tableFilterState={tableFilterState}
                    />
                )}
            </div>
        </div>
    );
}

export default TripQueryComponent