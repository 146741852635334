export const convertToCSV = <T extends Record<string, any>>(data: T[]): string => {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(trip => 
        Object.values(trip).map(value => {
            if (value === null || value === undefined) {
                return '""';
            } else if (typeof value === 'object') {
                if (value instanceof Date) {
                    return `"${value.toLocaleString()}"`;
                } else {
                    return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
                }
            } else if (typeof value === 'string') {
                return `"${value.replace(/"/g, '""')}"`;
            } else {
                return value;
            }
        }).join(',')
    );
    return [headers, ...rows].join('\n');
};

export const downloadCSV = <T extends Record<string, any>>(data: T[], filename: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (data.length === 0) {
            reject(new Error('No data to download'));
            return;
        }
        try {
            const csv = convertToCSV(data);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                resolve();
            } else {
                reject(new Error('Download not supported'));
            }
        } catch (error) {
            reject(error);
        }
    });
};

export default downloadCSV;