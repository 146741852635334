import { useState, useMemo, useCallback, useEffect } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from './ui/card'
import { Button } from './ui/button'
import { Input } from './ui/input'
import { UserViolation, ViolationQueryState } from '../types/Violation'
import { useViolationContext } from '../contexts/ViolationContext'
import { formatDate } from '../utils/formatDate'
import { usePersistedState } from '../hooks/usePersistedState'
import { generateColumns, filterColumns } from '../utils/tableUtils'
import DataTable from './common/DataTable'
import { AlertView } from './common/Alert'
import { ColumnDef } from '@tanstack/react-table'
import { useCSVDownload } from '../hooks/useCSVDownload'
import { Spinner } from './ui/spinner'
import { RefreshCw } from 'lucide-react'
import { Download } from 'lucide-react'
import { DataTableSkeleton } from './TableSkeleton'
import { useTrackNavigation } from '../hooks/useTrackNavigation'
const ViolationsComponent = (): JSX.Element => {
    const { data: users, loading, error, fetchData: fetchUsers, clearData: clearUsers } = useViolationContext()
    const [filteredUsers, setFilteredUsers] = useState<UserViolation[]>(users)
    const { isDownloading, handleDownload } = useCSVDownload<UserViolation>()
    useTrackNavigation()

    useEffect(() => {
        if (users.length > 0) {
            setFilteredUsers(users)
        }
    }, [users])
    
    const getDefaultState = (): ViolationQueryState => {
        const defaultEndDate = new Date()
        const defaultStartDate = new Date(defaultEndDate)
        defaultStartDate.setDate(defaultStartDate.getDate() - 2)

        return {
            startDate: formatDate(defaultStartDate),
            endDate: formatDate(defaultEndDate),
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 10,
            sortField: '',
            sortDirection: 'asc',
            filteredUsers: [],
            tableFilter: '',
        }
    }

    const [queryState, setQueryState] = usePersistedState<ViolationQueryState>({
        storageKey: 'ViolationState',
        defaultValue: getDefaultState()
    })
    
    const setTableFilter = useCallback((tableFilter: string) =>
        setQueryState(prev => ({ ...prev, tableFilter })),
        [setQueryState]
    )

    const setCurrentPage = useCallback((currentPage: number) =>
        setQueryState(prev => ({ ...prev, currentPage })),
        [setQueryState]
    )

    const setItemsPerPage = (itemsPerPage: number) => {
        setQueryState(prev => ({ ...prev, itemsPerPage, currentPage: 1 }))
    }

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setQueryState(prev => ({ ...prev, startDate: e.target.value }))

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setQueryState(prev => ({ ...prev, endDate: e.target.value }))

    const resetToDefaults = () => {
        setQueryState(getDefaultState())
        clearUsers()
    }

    const handleDownloadCSV = () => {
        if (filteredUsers.length === 0) return
        handleDownload({
            getData: () => filteredUsers,
            filename: 'user_data.csv',
            sanitizeData: (users) => users.map(({ user_id, ...rest }) => rest)
        })
    }
    
    const handleDataChange = useCallback((data: UserViolation[]) => {
        setFilteredUsers(data)
    }, [setFilteredUsers])

    const handleFindClick = useCallback(() => {
        fetchUsers({
            startDate: queryState.startDate,
            endDate: queryState.endDate
        })
        setQueryState(prev => ({ 
            ...prev, 
            tableFilter: '',
            currentPage: 1,
        }))
    }, [fetchUsers, queryState.startDate, queryState.endDate])

    const columns: ColumnDef<UserViolation, any>[] = useMemo(() => {
        const defaultColumns: ColumnDef<UserViolation, any>[] = [
            { accessorKey: 'user_uuid', header: 'User UUID' },
            { accessorKey: 'Highway Trips', header: 'In Corridor' },
            { accessorKey: 'completed_trips', header: 'Completed Trips' },
            { accessorKey: 'count_of_trip', header: 'Violations' },
        ]

        if (!users.length) return defaultColumns

        const allColumns = generateColumns<UserViolation>(users)
        const excludeFields: string[] = []
        return filterColumns(allColumns, excludeFields)
    }, [users])

    const paginationState = {
        currentPage: queryState.currentPage,
        itemsPerPage: queryState.itemsPerPage,
        onPageChange: setCurrentPage,
        onItemsPerPageChange: setItemsPerPage
    }

    const tableFilterState = {
        tableFilter: queryState.tableFilter,
        onTableFilterChange: setTableFilter
    }

    return (
        <div className="p-4">
            <div className="flex flex-col md:flex-row gap-5 mb-4">
                <Card className="flex-1 md:max-w-xl bg-white">
                    <CardHeader>
                        <CardTitle>Trip Violations</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="flex flex-col md:flex-row gap-4 items-end">
                            <div className="flex-1 space-y-1">
                                <label htmlFor="startDate" className="text-sm font-medium">Start Date</label>
                                <Input
                                    id="startDate"
                                    type="date"
                                    value={queryState.startDate}
                                    onChange={handleStartDateChange}
                                />
                            </div>
                            <div className="flex-1 space-y-1">
                                <label htmlFor="endDate" className="text-sm font-medium">End Date</label>
                                <Input
                                    id="endDate"
                                    type="date"
                                    value={queryState.endDate}
                                    onChange={handleEndDateChange}
                                />
                            </div>
                            <Button 
                                onClick={handleFindClick}
                                className="bg-green-600 hover:bg-green-700 text-white"
                            >
                                Find
                            </Button>
                        </div>
                    </CardContent>
                </Card>

                <div className="flex flex-col gap-2 md:justify-end self-end">
                        <Button
                            onClick={resetToDefaults}
                            variant="outline"
                            className="w-full md:w-auto bg-secondary hover:bg-secondary/90 text-secondary-foreground bg-green-600 text-white hover:bg-green-700"
                        >
                            <RefreshCw className="w-4 h-4 mr-2" />
                            Reset
                        </Button>
                        <Button
                            onClick={handleDownloadCSV}
                            variant="outline"
                            className="w-full md:w-auto bg-primary hover:bg-primary/90 text-primary-foreground bg-green-600 text-white hover:bg-green-700"
                            disabled={isDownloading || filteredUsers.length === 0}
                        >
                            {isDownloading ? (
                                <>
                                    <Spinner size="small" className="mr-2" />
                                    Downloading...
                                </>
                            ) : (
                                <>
                                    <Download className="w-4 h-4 mr-2" />
                                    Download CSV
                                </>
                            )}
                        </Button>
                    </div>
            </div>

            {error ? (
                <AlertView
                    message="An error has occurred"
                    onAction={handleFindClick}
                    buttonTitle="Retry"
                />
            ) : loading ? (
                <DataTableSkeleton rows={10} columns={4} />
            ) : (
                <DataTable
                    data={users}
                    columns={columns}
                    paginationState={paginationState}
                    onDataChange={handleDataChange}
                    tableFilterState={tableFilterState}
                />
            )}
        </div>
    )
}

export default ViolationsComponent