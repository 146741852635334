import { useState, useEffect } from 'react'

const MOBILE_BREAKPOINT = 768 // You can adjust this value as needed

export function useIsMobile(): boolean {
    const [isMobile, setIsMobile] = useState<boolean>(false)

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth < MOBILE_BREAKPOINT)
        }

        // Check on mount
        checkIsMobile()

        // Add event listener for window resize
        window.addEventListener('resize', checkIsMobile)

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('resize', checkIsMobile)
        }
    }, [])

    return isMobile
}