import { useState, useCallback, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { TripProvider } from './contexts/TripContext';
import { UserProvider } from './contexts/UserContext';
import { QuickSightProvider } from './contexts/QuickSightContext';
import { ViolationProvider } from './contexts/ViolationContext';
import { ResetProvider } from './contexts/ResetContext';
import { UserConfigProvider } from './contexts/UserConfigContext';
import { getValidAuthToken } from './utils/auth';
import { ErrorBoundary } from './components/ErrorBoundary';
import { NavigationTrackerProvider } from './contexts/NavigationTrackerContext';
import awsExports from './aws-exports';
import AppContent from './components/AppContent';
import RootLayout from './components/Layout';
import logger from './services/logger';
import '@aws-amplify/ui-react/styles.css';
import { signOut } from 'aws-amplify/auth';
import { LAST_ACTIVITY_KEY } from './constants';

Amplify.configure(awsExports);

export default function App(): JSX.Element {
  const [authToken, setAuthToken] = useState<string | null>(null);

  const updateAuthToken = useCallback(async () => {
    try {
      const token = await getValidAuthToken();
      setAuthToken(token as string | null);
    } catch (error) {
      await logger.log(`Failed to update auth token: ${error}`, { source: { file: __filename } });
      setAuthToken(null);
    }
  }, []);

  const handleSignOut = useCallback(async (): Promise<void> => {
    try {
      await signOut();
      await updateAuthToken();
      localStorage.removeItem(LAST_ACTIVITY_KEY);
    } catch (error) {
      await logger.log(`Error signing out: ${error}`, { source: { file: __filename } });
    }
  }, [updateAuthToken]);

  useEffect(() => {
    // Refresh token every 55 minutes (considering 1 hour expiration)
    const refreshInterval = setInterval(updateAuthToken, 55 * 60 * 1000);
    return () => clearInterval(refreshInterval);
  }, [updateAuthToken]);

  return (
    <ErrorBoundary>
      <NavigationTrackerProvider>
        <Router basename='/'>
          <Authenticator
            variation="modal"
            components={{
              SignUp: {
                Footer() {
                  return null;
                },
              },
            }}
            hideSignUp={true}
          >
            {({ user }) => (
              <RootLayout
                username={user?.signInDetails?.loginId || ''}
                onSignOut={handleSignOut}
              >
                <UserConfigProvider>
                  <ViolationProvider>
                    <TripProvider>
                      <UserProvider>
                        <ResetProvider>
                          <QuickSightProvider>
                            <AppContent initialUser={user} updateAuthToken={updateAuthToken} />
                          </QuickSightProvider>
                        </ResetProvider>
                      </UserProvider>
                    </TripProvider>
                  </ViolationProvider>
                </UserConfigProvider>
              </RootLayout>
            )}
          </Authenticator>
        </Router>
      </NavigationTrackerProvider>
    </ErrorBoundary>
  );
}