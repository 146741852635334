import { Toaster } from "./ui/toaster"
import { SidebarProvider, SidebarTrigger } from "./ui/sidebar"
import AppSidebar from "./ui/app-sidebar"
import { DynamicBreadcrumb } from "./BreadCrumb"

interface RootLayoutProps {
  username: string;
  onSignOut: () => Promise<void>;
  children: React.ReactNode;
}

export default function RootLayout({ username, onSignOut, children }: RootLayoutProps) {
  return (
    <SidebarProvider defaultOpen={false}>
      <AppSidebar username={username} onSignOut={onSignOut} />
      <main className="flex-1 flex flex-col min-h-screen bg-neutral-100">
        <SidebarTrigger className="text-green-600" />
        <DynamicBreadcrumb />
        <div className="flex-1 w-full p-4">
          {children}
        </div>
      </main>
      <Toaster />
    </SidebarProvider>
  )
}