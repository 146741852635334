import { Car, ChartBar, ChevronUp, FileWarning, LogOut, User2, Users } from "lucide-react"
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarGroupContent,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
} from "./sidebar"
import { 
    DropdownMenu, 
    DropdownMenuContent, 
    DropdownMenuItem, 
    DropdownMenuTrigger 
} from "./dropdown-menu"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "./alert-dialog"
import rideflagLogo from "../../images/rideflaglogo-darkmode.png"
import { useNavigate } from 'react-router-dom';
import { useState } from "react"
import { useNavigationTracker } from "../../contexts/NavigationTrackerContext"

// Menu items.
const items = [
    {
        title: "Trip Query",
        url: "/trips",
        icon: Car,
    },
    {
        title: "User Profiles",
        url: "/userProfiles",
        icon: Users,
    },
    {
        title: "Violations",
        url: "/violations",
        icon: FileWarning,
    },
    {
        title: "Analytics",
        url: "/analytics",
        icon: ChartBar,
    },
]

interface AppSidebarProps {
    username: string;
    onSignOut: () => Promise<void>;
}

const AppSidebar = ({ username, onSignOut }: AppSidebarProps): React.ReactElement => {
    const navigate = useNavigate();
    const [isSignoutDialogOpen, setIsSignoutDialogOpen] = useState(false);
    const { getLastVisitedPath } = useNavigationTracker();

    const handleNavigateToBasePath = (basePath: string): void => {
        const lastVisitedPath = getLastVisitedPath(basePath);
        if (lastVisitedPath) {
            navigate(lastVisitedPath);
        } else {
            navigate(basePath);
        }
    };

    const handleSignout = ():void => {
        onSignOut();
        navigate('/');
    }

    const handleAlertDialog = () => {
        return (
            <AlertDialog 
                open={isSignoutDialogOpen} 
                onOpenChange={setIsSignoutDialogOpen}
            >
                <AlertDialogContent className="bg-white">
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure you want to sign out?</AlertDialogTitle>
                        <AlertDialogDescription>
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleSignout}>Continue</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        )
    }
    return (
        <Sidebar 
            variant="inset" 
            collapsible="icon" 
            hoverToggle={true} 
            className="bg-green-600"
        >
            {handleAlertDialog()}
            <SidebarHeader className="flex items-center gap-2">
                <div className="rounded-full border-2 border-white inline-flex w-[40px] h-[40px] items-center justify-center">
                    <img
                        src={rideflagLogo}
                        alt="RideFlag Logo"
                        width="25px"
                        height="25px"
                        style={{ objectFit: 'contain' }}
                        onClick={() => navigate('/')}
                    />
                </div>
            </SidebarHeader>
            <SidebarContent >
                <SidebarGroup>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            {items.map((item) => (
                                <SidebarMenuItem key={item.title}>
                                    <SidebarMenuButton  asChild onClick={() => handleNavigateToBasePath(item.url)}>
                                        <div  className="text-white hover:bg-green-700">
                                            <item.icon className="text-white" />
                                            <span>{item.title}</span>
                                        </div>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                            ))}
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>
            </SidebarContent>
            <SidebarFooter>
                <SidebarMenu>
                    <SidebarMenuItem>
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <SidebarMenuButton>
                                    <User2 className="text-white" />
                                    <span className="text-white">{username}</span>
                                    <ChevronUp className="ml-auto text-white" />
                                </SidebarMenuButton>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                                side="top"
                                className="w-[--radix-popper-anchor-width] bg-white"
                            >
                                <DropdownMenuItem onClick={() => setIsSignoutDialogOpen(true)}>
                                    <LogOut className="text-slate-400" />
                                    <span>Sign out</span>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </SidebarMenuItem>
                </SidebarMenu>
            </SidebarFooter>
        </Sidebar>
    )
}

export default AppSidebar