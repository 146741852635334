import { useState, useEffect } from 'react';

interface PersistedStateOptions<T> {
  storageKey: string;
  defaultValue: T;
  storage?: Storage; // allows for different storage types (sessionStorage/localStorage)
}

export function usePersistedState<T>({ 
  storageKey, 
  defaultValue, 
  storage = sessionStorage 
}: PersistedStateOptions<T>) {
  const getInitialState = (): T => {
    const savedState = storage.getItem(storageKey);
    if (savedState) {
      return JSON.parse(savedState);
    }
    return defaultValue;
  };

  const [state, setState] = useState<T>(getInitialState());

  // Load state on mount
  useEffect(() => {
    const savedState = storage.getItem(storageKey);
    if (savedState) {
      setState(JSON.parse(savedState));
    }
  }, [storageKey, storage]);

  // Save state on changes
  useEffect(() => {
    storage.setItem(storageKey, JSON.stringify(state));
  }, [state, storageKey, storage]);

  return [state, setState] as const;
} 