import { Skeleton } from "./ui/skeleton"

export function QuickSightDashboardSkeleton() {
    return (
        <div className="w-full h-[1600px] p-4 bg-neutral-100 dark:bg-gray-800">
            <div className="flex flex-wrap gap-4 mb-6">
                {[...Array(4)].map((_, index) => (
                    <Skeleton
                        key={`control-${index}`}
                        className="h-10 w-[110px] rounded-md animate-pulse"
                    />
                ))}
            </div>
            <div className="grid grid-cols-4 gap-4 mb-4">
                {[...Array(4)].map((_, index) => (
                    <Skeleton key={`top-${index}`} className="w-full h-[200px] rounded-lg animate-pulse" />
                ))}
            </div>
            {[...Array(3)].map((_, rowIndex) => (
                <div key={`row-${rowIndex}`} className="grid grid-cols-2 gap-4 mb-4">
                    {[...Array(2)].map((_, colIndex) => (
                        <Skeleton
                            key={`tile-${rowIndex}-${colIndex}`}
                            className="w-full h-[490px] rounded-lg animate-pulse"
                        />
                    ))}
                </div>
            ))}
        </div>
    )
}