import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import logger from './services/logger';

// Global error handlers
window.addEventListener('error', async (event: ErrorEvent): Promise<void> => {
  await logger.log(`Runtime error: ${event.error}`, { source: { file: __filename } });
});

window.addEventListener('unhandledrejection', async (event: PromiseRejectionEvent): Promise<void> => {
  await logger.log(`Unhandled promise rejection: ${event.reason}`, { source: { file: __filename } });
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
