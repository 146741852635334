import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigationTracker } from '../contexts/NavigationTrackerContext';

export const useTrackNavigation = () => {
  const location = useLocation();
  const { setLastVisitedPath } = useNavigationTracker();

  useEffect(() => {
    const basePath = '/' + location.pathname.split('/')[1];
    setLastVisitedPath(basePath, location.pathname);
  }, [location.pathname, setLastVisitedPath]);
};