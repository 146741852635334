import React, { createContext, useState, useContext } from 'react';
import { post } from 'aws-amplify/api'; 
import { getValidAuthToken } from '../utils/auth';
import logger from '../services/logger';

interface DataContextType<T, Q> {
  data: T[];
  loading: boolean;
  error: string | null;
  fetchData: (requestBody: Q) => Promise<void>; 
  clearData: () => void;
}

export const createDataContext = <T, Q extends Record<string, any>>(
  apiName: string,
  path: string,
  parseResponse: (response: any) => T[]
) => {

  const DataContext = createContext<DataContextType<T, Q>>({
    data: [],
    loading: false,
    error: null,
    fetchData: async () => {
      console.log('Default fetchData called - context not properly initialized');
    },
    clearData: () => { }
  });

  const useDataContext = () => useContext(DataContext);

  const DataProvider = ({ children }: { children: React.ReactNode }) => {
    const [data, setData] = useState<T[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchData = async (requestBody: Q) => {
      setLoading(true);
      setError(null);
      try {
        const authToken = await getValidAuthToken(); 
        
        const restOperation = post({
          apiName,
          path,
          options: {
            headers: { 
              Authorization: authToken || '',
              'Content-Type': 'application/json' 
            },
            body: requestBody 
          }
        });

        const response = await restOperation.response;
        
        if (response.body instanceof ReadableStream) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let result = '';
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            result += decoder.decode(value);
          }

          const parsedData = parseResponse(JSON.parse(result));
          setData(parsedData);
        } else {
          setData(parseResponse(response));
        }
      } catch (err) {
        await logger.log(`Error fetching data: ${err}`, { source: { file: __filename } });
        setError(err instanceof Error ? err.message : 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    const clearData = () => {
      setData([]);
    };

    return (
      <DataContext.Provider value={{ data, loading, error, fetchData, clearData }}>
        {children}
      </DataContext.Provider>
    );
  };

  return { useDataContext, DataProvider };
};