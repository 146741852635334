import { createDataContext } from './Context';
import { UserViolation, ViolationQueryParams } from '../types/Violation';

const parseResponse = (response: UserViolation[]): UserViolation[] => {
  return Array.isArray(response) ? response : []; 
};

export const { useDataContext: useViolationContext, DataProvider: ViolationProvider } = createDataContext<UserViolation, ViolationQueryParams>(
  'userViolationQueryAPI',
  '/userViolationQuery',
  parseResponse
);
