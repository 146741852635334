const activityEvents: string[] = [
    // Mouse events
    'mousedown',
    'mousemove',
    'mouseup',
    'mouseenter',
    
    // Keyboard events
    'keydown',
    
    // Scroll events
    'scroll',
    'wheel',
    
    // Focus events
    'focus',
    'focusin',
    
    // Input events
    'input',
    'change',
    
    // Clipboard events
    'cut',
    'copy',
    'paste',
    
    // Form events
    'submit',
    
    // Window events
    'resize',
  ];

  export default activityEvents;